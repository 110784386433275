import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLoader from "./components/PageLoader";
import LoadingBar from "react-top-loading-bar";
import localContext from "./localContext";
import i18n from "./i18n";
import { useEffect, useRef  } from "react";
import MobileMenu from "./components/MobileMenu";
import LiveChat from './chatbox/LiveChat';
import { debounce } from 'lodash';

import VendorAuthorized from './vendor/VendorAuthorized';
import ProtectedRoute from './login/ProtectedRoute';
import RegistrationSuccess from "./vendor/RegistrationSuccess";
import ResetPassword from "./login/ResetPassword";
import ForgotPassword from "./login/ForgotPassword";
import ResetBeforeLogin from "./login/ResetBeforeLogin";
//import Investment_Details from "./investment/Asad";


const LiveChatWithResizeObserver = () => {
  const liveChatRef = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      // Your resize handling logic
      console.log('Resized!');
    }, 300);
  
    const liveChatElement = liveChatRef.current;
  
    if (liveChatElement) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(liveChatElement);
  
      // Cleanup on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []); // Empty dependency array means this effect runs once on mount
  // Empty dependency array means this effect runs once on mount

  return <LiveChat ref={liveChatRef} />;
};







const NavBar = lazy(() => import("./navbar/NavBar"));
const Investment_Details = lazy(() => import("./investment/Investment_Details"));
const Home = lazy(() => import("./home/Home"));
const About = lazy(() => import("./about/About"));
const Laws = lazy(() => import("./about/Laws"));
const LawDetails = lazy(() => import("./about/LawDetails"));
const Policy = lazy(() => import("./about/Policy"));
const PolicyDetails = lazy(() => import("./about/PolicyDetails"));
const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
const Dams = lazy(() => import("./dams/Dams"));
const DamsDetails = lazy(() => import("./dams/DamsDetails"));
const NewsPage = lazy(() => import("./news/NewsPage"));
const NewsDetails = lazy(() => import("./news/NewsDetails"));
const Tender = lazy(() => import("./tender/Tender"));
const TenderDetails = lazy(() => import("./tender/TenderDetails"));
const SmallPurchaseDetails = lazy(() => import("./tender/SmallPurchaseDetails"));
const SmallPurchase = lazy(() => import("./tender/SmallPurchase"));
const Career = lazy(() => import("./career/Career"));
const CareerDetails = lazy(() => import("./career/CareerDetails"));
const GalleryGrid = lazy(() => import("./gallery/GalleryGrid"));
const GalleryImages = lazy(() => import("./gallery/GalleryImages"));
const VideoGrid = lazy(() => import("./gallery/VideoGrid"));
const ArticlesGrid = lazy(() => import("./gallery/ArticlesGrid"));
const Magazines = lazy(() => import("./magazine/Magazines"));
const ContractAwards = lazy(() => import("./awards/ContractAwards"));
const AwardDetails = lazy(() => import("./awards/AwardDetails"));
const Report = lazy(() => import("./reports/Report"));
const ReportType = lazy(() => import("./pages/ReportType"));
const Investment = lazy(() => import("./pages/Investment"));
const ReportDetails = lazy(() => import("./reports/ReportDetails"));
const varInvDet = lazy(() => import("./investment/InvDet"));
const Contact = lazy(() => import("./pages/Contact"));
const Static = lazy(() => import("./pages/Static"));
const Footer = lazy(() => import("./pages/Footer"));
const NoMAtch = lazy(() => import("./pages/404"));
const ContactDir = lazy(() => import("./contact_directory/ContactDirectoryPage"));
const Complaint = lazy(() => import("./complaints/CustomerComplaintForm"));
const CustomerForms = lazy(() => import("./customer_form/CustomerForm"));
const SearchResult = lazy(() => import("./search/SearchResultsPage"));
const VendorSignupForm = lazy(() => import("./vendor/VendorSignupForm"));
const Login = lazy(() => import("./login/Login"));




const App = () => {
 const [progress, setProgress] = useState(0);
 const [local,setLocal] = useState("English");




  
 i18n.on("languageChanged", (lng) => setLocal(i18n.language))
 useEffect(() =>{
  i18n.changeLanguage(localStorage.getItem("lang"));
 })

  let count = 0
  const progressBar = (param) => {
    count+=param
    setProgress(count);
  };



  const [isAuthenticated, setIsAuthenticated] = useState(false);



  return (
    <Router>
    <localContext.Provider value={{local,setLocal}}>
      <Suspense fallback={<PageLoader />}>
        <LoadingBar height={3} color="#f11946" progress={progress} />
        <NavBar />
        <MobileMenu />
        <Routes>
          <Route path="/" element={<Home progressBar={progressBar} />} />
          <Route path="/home" element={<Home progressBar={progressBar} />}/>
          <Route path="/about" element={<About />} />
          <Route path="/laws" element={<Laws />} />
          <Route path="/law-details/:id" element={<LawDetails />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/policy-details/:id" element={<PolicyDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project-details/:id" element={<ProjectDetails />} />
          <Route path="/dams" element={<Dams />} />
          <Route path="/dams-details/:id" element={<DamsDetails />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news-details/:id" element={<NewsDetails />} />
          <Route path="/tender" element={<Tender />} />
          <Route path="/tender-details/:id" element={<TenderDetails />} />
          <Route path="/small-purchase" element={<SmallPurchase />} />
          <Route path="/small-purchase-details/:id" element={<SmallPurchaseDetails />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career-details/:id" element={<CareerDetails />} />
          <Route path="/gallery" element={<GalleryGrid />} />
          <Route path="/gallery-images/:id" element={<GalleryImages />} />
          <Route path="/videos" element={<VideoGrid />} />
          <Route path="/articles" element={<ArticlesGrid />} />
          <Route path="/magazines" element={<Magazines />} />
          <Route path="/awards" element={<ContractAwards />} />
          <Route path="/award-details/:id" element={<AwardDetails />} />
          <Route path="/reports" element={<Report />} />
          <Route path="/report-details/:id" element={<ReportDetails />} />
          <Route path="/indet/:id" element={varInvDet} />
          <Route path="/report-type/:type" element={<ReportType />} />
          <Route path="/investment/:type" element={<Investment />} />
          <Route path="/category/:type" element={<Investment />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/static/:id" element={ <Static />} />
          <Route path="*" element={<NoMAtch />} />
          <Route path="/chat" element={<LiveChat />} />

          <Route path="/chat" element={<LiveChatWithResizeObserver />} />

          <Route path="/contact-dir" element={<ContactDir />} />

          <Route path="/complaint" element={<Complaint />} />
          <Route path="/customer-form" element={<CustomerForms />} />
          <Route path="/search-results/:item" element={<SearchResult />} />
          <Route path="/vendor" element={<VendorSignupForm />} />
          <Route path="/vendor-login" element={<Login />} />
          <Route path="/vendor-authorized" element={<VendorAuthorized />} />
          <Route path="/vendor-registered" element={<RegistrationSuccess />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-page/:token/:email" element={<ResetBeforeLogin />} />
          <Route path="/Investment_Details/:id" element={<Investment_Details />} />
          <Route path="/category_Details/:id" element={<Investment_Details />} />
          

        </Routes>
       
       


      <Footer />
      </Suspense>
      </localContext.Provider>
    </Router>

    
  );
};

export default App;
